import { useCallback } from 'react';

import { getGiftNotificationTitle } from '@zola/component-public-registry/src/utils/registry';
import type { WProductView, WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import type { QuickCheckoutModalProps } from '@zola/zola-ui/src/components/Checkout/QuickCheckoutModal';
import { negative } from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import { clientIsBaby } from '@zola-helpers/client/dist/es/util/clientIsBaby';

import {
  CheckoutRequest,
  createPaymentIntent,
  getCartByStore,
  removeCartItems,
  submitCheckout,
} from '@/lib/client/api/cart';
import {
  addDigitalGreetingCardToCart,
  getDigitalGreetingCard,
  getDigitalGreetingCardTemplate,
} from '@/lib/client/api/digitalGreetingCard';
import featureFlags from '@/util/featureFlags';
import { getStripePublishableKey } from '@/util/stripe';

import { addItemToCart } from '../actions/CartActions';
import { setSelectedRegistryItem, updateCollectionOrder } from '../actions/ManageRegistryActions';
import { hideModal, showModal } from '../actions/ModalActions';
import { getCollectionItemInfo } from '../selectors/registrySelectors';
import { useAppDispatch, useAppSelector } from '../store';
import { trackProductAdded } from '../util/trackingHelper';

const OPEN_MODAL_EVENT = 'OPEN_MODAL_EVENT';

/**
 * Creates payload object for custom event from product and supplemental data
 * @param {Object} product - registry collection item
 * @param {Number} quantity - amount of a product
 * @param {String} cartId - cart id to which product is being added to.
 * @returns {Object} - complete payload for custom event tracking
 */
export const buildCustomEventData = (
  product: WRegistryCollectionItemView,
  cartId?: string | null,
  registryId?: string | null,
  quantity = 1
) => {
  const sku = product.sku_object_id;
  const productId = product.product_id;
  const productLookId = product.product_look_id;
  const { name, price } = product;
  const brand = product.brand && product.brand.name;
  const imageUrl = product.images?.[0].medium;

  return {
    cart_id: cartId,
    registry_id: registryId,
    product_id: productId,
    sku,
    name,
    brand,
    price,
    quantity,
    image_url: imageUrl,
    variant: productLookId,
    location: 'PUBLIC_REGISTRY',
    section: 'QUICK_ADD',
  };
};

/**
 * Used by the quick checkout modal to determine what to do when the modal is closed
 */
let quickCheckoutPurchaseSuccess = false;

export const useRegistryActions = (registryKey: string, isManage = false) => {
  const dispatch = useAppDispatch();
  const collectionItemInfo = useAppSelector(getCollectionItemInfo);

  const handleItemRearrange = (currentPosition: number, targetPosition: number) => {
    dispatch(updateCollectionOrder(currentPosition, targetPosition)).catch(() => null);
  };

  const handleEditItemClick = (item: WRegistryCollectionItemView) => {
    dispatch(setSelectedRegistryItem(item));
  };

  /**
   * Confirm an external item purchase or Venmo contribution
   */
  const showRegistryProductPurchasedModal = useCallback(
    ({ purchased, isCashFund }: { purchased: boolean; isCashFund: boolean }) => {
      let title = getGiftNotificationTitle(collectionItemInfo, isCashFund);
      let hideCheck = false;
      if (!purchased) {
        title = 'Thank you for letting us know.';
        hideCheck = true;
      }
      dispatch(
        showModal(
          'REGISTRY_PRODUCT_PURCHASED_MODAL',
          {
            title,
            hideCheck,
            onContinue: () => {
              dispatch(hideModal());
            },
          },
          {
            className: 'registry-product-purchased-modal',
            size: 'sm',
            bottomModalOnMobile: true,
          }
        )
      );
    },
    [collectionItemInfo, dispatch]
  );

  /**
   * Purchase a digital greeting card
   */
  const showQuickCheckoutModal = useCallback(
    ({
      stripeClientSecret,
      amountCents,
      productName,
      paymentIntentId,
      orderId,
      giftGiverName,
      giftGiverEmail,
      message,
      isCashFund,
    }: {
      stripeClientSecret: string;
      amountCents: number;
      productName: string;
      paymentIntentId: string;
      orderId: string;
      giftGiverName: string;
      giftGiverEmail: string;
      message: string;
      isCashFund: boolean;
    }) => {
      quickCheckoutPurchaseSuccess = false;
      dispatch(
        showModal(
          'QUICK_CHECKOUT_MODAL',
          {
            isPaymentIntentUpgrade: featureFlags.get('paymentIntentQuickCheckoutUpgrade'),
            stripePublishableKey: getStripePublishableKey(),
            stripeClientSecret,
            amountCents,
            productName,
            onSuccess: (result) => {
              const { confirmationToken, handleStripeNextAction } = result || {};
              quickCheckoutPurchaseSuccess = true;
              const parts = giftGiverName.trim().split(/\s+/);
              const firstName = parts.shift() || '';
              const lastName = parts.join(' ');
              const request: CheckoutRequest = {
                payment_intent_id: paymentIntentId,
                order_email: giftGiverEmail,
                billing_info: {
                  first_name: firstName,
                  last_name: lastName,
                },
                shipping_info: {
                  first_name: firstName,
                  last_name: lastName,
                  email: giftGiverEmail,
                },
                gift_message: message,
                dgc_group_order_id: orderId,
                stripe_confirmation_token_id: confirmationToken?.id,
              };
              submitCheckout(request)
                .then((checkoutResponse) => {
                  if (
                    !checkoutResponse.successful &&
                    checkoutResponse?.errorCategory?.reason === 'requires-additional-action'
                  ) {
                    if (handleStripeNextAction) {
                      handleStripeNextAction();
                      return;
                    }
                  }
                  if (!checkoutResponse.successful && checkoutResponse.userMessage) {
                    dispatch(negative({ headline: checkoutResponse.userMessage }));
                  }
                  showRegistryProductPurchasedModal({ purchased: true, isCashFund });
                })
                .catch((errorResponse) => {
                  const headline =
                    errorResponse.response?.error?.message ||
                    'There was a problem processing your order.';
                  dispatch(negative({ headline }));
                });
            },
          } as QuickCheckoutModalProps,
          {
            className: 'quick-checkout-modal',
            size: 'sm',
            bottomModalOnMobile: true,
            explicitClose: true,
            onExplicitClose: () => {
              if (quickCheckoutPurchaseSuccess) {
                showRegistryProductPurchasedModal({ purchased: true, isCashFund });
              } else {
                // Remove the digital greeting cart from the cart
                getCartByStore('WEDDING_REGISTRY')
                  .then((cart) => {
                    const itemIds = cart.items?.map((item) => item.id as string) || [];
                    return removeCartItems(itemIds, 'WEDDING_REGISTRY');
                  })
                  .catch(() => null);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                showDigitalGreetingCardModal({
                  orderId,
                  giftGiverName,
                  giftGiverEmail,
                  isCashFund,
                });
              }
            },
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * Select a digital greeting card and add a gift message
   */
  const showDigitalGreetingCardModal = useCallback(
    ({
      orderId,
      giftGiverName,
      giftGiverEmail,
      isCashFund,
    }: {
      orderId: string;
      giftGiverName: string;
      giftGiverEmail: string;
      isCashFund: boolean;
    }) => {
      getDigitalGreetingCard()
        .then((response) => {
          dispatch(
            showModal(
              'DIGITAL_GREETING_CARD_MODAL',
              {
                digitalGreetingCard: response,
                loadDigitalGreetingCardTemplate: getDigitalGreetingCardTemplate,
                onSkip: () => {
                  showRegistryProductPurchasedModal({ purchased: true, isCashFund });
                },
                onContinue: (skuId: string, priceCents: number, message: string) => {
                  addDigitalGreetingCardToCart(skuId, message)
                    .then((cart) => {
                      return createPaymentIntent({ cart_id: cart.cart_id });
                    })
                    .then((paymentIntent) => {
                      showQuickCheckoutModal({
                        stripeClientSecret: paymentIntent.clientSecret as string,
                        amountCents: priceCents,
                        productName: 'Personalized e-card',
                        paymentIntentId: paymentIntent.paymentIntentId as string,
                        orderId,
                        giftGiverName,
                        giftGiverEmail,
                        message,
                        isCashFund,
                      });
                    })
                    .catch(() => {
                      dispatch(
                        negative({
                          headline:
                            'There was a problem adding the e-card to your cart. Please try again.',
                        })
                      );
                    });
                },
              },
              {
                className: 'digital-greeting-card-modal',
                size: 'xl',
                bottomModalOnMobile: true,
              }
            )
          );
        })
        .catch(() => {
          showRegistryProductPurchasedModal({ purchased: true, isCashFund });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * Show product details for a registry item
   *
   * This is the start of the digital greeting card flow, which shows the following modals in
   * succession after an external item purchase or Venmo contribution:
   * - ProductDetailModal
   * - DGCModal
   * - QuickCheckoutModal
   * - RegistryProductPurchasedModal
   *
   * The code to manage the flow is duplicated between `web-registry` and `web-wedding`, because
   * each app has its own Redux store, modal management and API calls.
   */
  const handleProductClick = useCallback(
    (item: WRegistryCollectionItemView | WProductView) => {
      const collectionItemId = 'object_id' in item ? item.object_id : '';
      const currentUrl = `${window.location.pathname}${window.location.search}`;
      const isCashFund = 'cash_fund' in item && Boolean(item.cash_fund);
      dispatch(
        showModal(
          'PRODUCT',
          {
            collectionItemId,
            registryKey,
            collectionItem: item,
            registryOriginUrl: currentUrl,
            onExternalPurchaseFlowComplete: (
              step: string,
              orderId?: string,
              giftGiverName?: string,
              giftGiverEmail?: string
            ) => {
              const isMissingOrderInfo = !orderId || !giftGiverName || !giftGiverEmail;
              if (
                !featureFlags.get('enableDGCFlow') ||
                step === 'DIDNT_BUY' ||
                isMissingOrderInfo
              ) {
                showRegistryProductPurchasedModal({ purchased: step !== 'DIDNT_BUY', isCashFund });
              } else {
                showDigitalGreetingCardModal({
                  orderId,
                  giftGiverName,
                  giftGiverEmail,
                  isCashFund,
                });
              }
            },
            onVenmoFlowComplete: (
              contributed: boolean,
              orderId?: string,
              giftGiverName?: string,
              giftGiverEmail?: string
            ) => {
              const isMissingOrderInfo = !orderId || !giftGiverName || !giftGiverEmail;
              if (!featureFlags.get('enableDGCFlow') || !contributed || isMissingOrderInfo) {
                showRegistryProductPurchasedModal({ purchased: contributed, isCashFund });
              } else {
                showDigitalGreetingCardModal({
                  orderId,
                  giftGiverName,
                  giftGiverEmail,
                  isCashFund,
                });
              }
            },
            enableDGCFlow: !isManage,
          },
          {
            className: 'product-modal',
            size: 'xl',
            bottomModalOnMobile: true,
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, registryKey]
  );

  const handleAddItemToCart = (item: WRegistryCollectionItemView, quantity = 1) => {
    const collectionItemId = item.object_id;
    const skuId = item.sku_object_id;
    const request = {
      collectionItemId,
      skuId: skuId as string,
      quantity,
      itemOrigin: clientIsBaby() ? 'BABY_REGISTRY' : 'WEDDING_REGISTRY',
    };

    dispatch(addItemToCart(request))
      .then((result) => {
        const { cart_id, cart_items } = result.payload;
        const purchasedItem = cart_items?.find(
          (cartItem) => cartItem.collection_item_id === collectionItemId
        );
        const { registry_id } = purchasedItem || {};
        const eventData = buildCustomEventData(item, cart_id, registry_id, quantity);
        trackProductAdded(eventData);
        window.dispatchEvent(new CustomEvent(OPEN_MODAL_EVENT, { detail: 'CART' }));
      })
      .catch((error: Error) => {
        window.dispatchEvent(
          new CustomEvent(`UPDATE_MAX_QUANTITY_${collectionItemId}`, { detail: error })
        );
      });
  };

  const handleItemClick = (item: WRegistryCollectionItemView | WProductView, quantity = 1) => {
    if (!('object_id' in item)) {
      return handleProductClick(item);
    }

    const isGroupGift = item.contributions?.group_gift;
    const isExternalGift =
      item.type === ('EXTERNAL' as unknown as WRegistryCollectionItemView.TypeEnum) &&
      item.cash_fund === false;

    if (isGroupGift || isExternalGift) {
      handleProductClick(item);
    } else {
      handleAddItemToCart(item, quantity);
    }

    return null;
  };

  return {
    handleEditItemClick,
    handleItemClick,
    handleProductClick,
    handleItemRearrange,
  };
};
