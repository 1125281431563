import { useEffect, useState } from 'react';

import { CameraPlusIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CameraPlus';
import { PencilIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Pencil';

import { getRegistry } from '@/selectors/registrySelectors';
import { useAppDispatch, useAppSelector } from '@/store';
import { RegistryType } from '@/types/registry';

import {
  Hero,
  HeroBanner,
  HeroBannerImage,
  HeroBannerText,
  HeroCoupleImage,
  HeroCoupleName,
  HeroWeddingDate,
  HeroEditButton,
  HeroEditButtonText,
  HeroGreetingSection,
  HeroGreetingContent,
} from './RegistryHero.styles';
import { requestRegistryInfoById } from '../../../actions/RegistryActions';
import { getEventDateMessage } from '../../../util/registry';
import CustomizeHeroSideDrawer from '../CustomizeHeroSideDrawer';

const EMPTY_GREETING = 'Welcome guests to your registry with a short message!';

interface RegistryHeroProps {
  editable?: boolean;
  isSSR?: boolean;
}

const RegistryHeroContent = ({
  editable,
  registryDetails,
}: {
  editable: boolean;
  registryDetails: RegistryType;
}) => {
  const [customize, setCustomize] = useState(false);

  const {
    name,
    greeting,
    event_date: eventDate,
    hero_image: heroImage,
    profile_image: profileImage,
  } = registryDetails;
  const eventDateMessage = getEventDateMessage(eventDate ?? undefined);
  const isGreetingShown = !!greeting || editable;

  const hideProfileImage = !profileImage && !editable;
  return (
    <>
      <Hero onClick={() => editable && setCustomize(true)} editable={editable}>
        <HeroBanner hasImage={!!heroImage}>
          <HeroBannerImage
            as={heroImage ? 'img' : 'div'}
            editable={editable}
            src={heroImage ?? undefined}
            hasImage={!!heroImage}
            alt=""
          />
          <div />
          <HeroBannerText darken={!heroImage}>
            <HeroCoupleName data-testid="couple-name">{name}</HeroCoupleName>
            <HeroWeddingDate data-testid="event-date">{eventDateMessage}</HeroWeddingDate>
            <HeroEditButton editable={editable} colorway="cloudAlt">
              <PencilIcon height={24} width={24} />
              <HeroEditButtonText>Customize</HeroEditButtonText>
            </HeroEditButton>
          </HeroBannerText>
          {!hideProfileImage &&
            (profileImage ? (
              <HeroCoupleImage src={profileImage} hasImage alt="" />
            ) : (
              <HeroCoupleImage as="div" hasImage={false}>
                <CameraPlusIcon width={30} height={30} />
              </HeroCoupleImage>
            ))}
        </HeroBanner>
        {isGreetingShown && (
          <HeroGreetingSection>
            <HeroGreetingContent>{greeting || EMPTY_GREETING}</HeroGreetingContent>
          </HeroGreetingSection>
        )}
      </Hero>
      {editable && (
        <CustomizeHeroSideDrawer isOpen={customize} onClose={() => setCustomize(false)} />
      )}
    </>
  );
};
export const RegistryHero = (props: RegistryHeroProps): JSX.Element => {
  const { editable, isSSR } = props;
  const dispatch = useAppDispatch();

  const registryDetails = useAppSelector(getRegistry);

  useEffect(() => {
    if (!isSSR) {
      dispatch(requestRegistryInfoById);
    }
  }, [dispatch]);

  if (registryDetails) {
    return <RegistryHeroContent editable={editable ?? false} registryDetails={registryDetails} />;
  }
  return <></>;
};
