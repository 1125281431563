import {
  updateFilters,
  clearFilters,
  updateSort,
  updateCollectionObjectId,
  searchRegistryCollection,
} from '@/actions/ManageRegistryActions';
import { Filters } from '@/components/common/Filters';
import type { Filter } from '@/components/common/Filters/type';
import {
  getManageRegistryCollectionList,
  getManageRegistryCollectionObjectId,
  getManageRegistryFacets,
  getManageRegistryFilters,
  getManageRegistrySortBy,
  getManageRegistryTotal,
} from '@/selectors/manageRegistrySelectors';
import { getRegistry } from '@/selectors/registrySelectors';
import { useAppDispatch, useAppSelector } from '@/store';

import { getCollectionOption, mapWAwsFacetToFilter } from './utils';

interface RegistryFiltersProps {
  showSort?: boolean;
}

export const RegistryFilters: React.FC<RegistryFiltersProps> = ({ showSort }) => {
  const dispatch = useAppDispatch();
  const facetValues = useAppSelector(getManageRegistryFilters);
  const collectionList = useAppSelector(getManageRegistryCollectionList);
  const collectionObjectId = useAppSelector(getManageRegistryCollectionObjectId);
  const sortBy = useAppSelector(getManageRegistrySortBy);
  const facets = useAppSelector(getManageRegistryFacets);
  const total = useAppSelector(getManageRegistryTotal);
  const registry = useAppSelector(getRegistry);

  const onChange = (key?: string, val?: string) => {
    if (!key || val === undefined) return;

    if (key === 'sort') {
      dispatch(updateSort(val));
    } else if (key === 'collection_object_id') {
      if (val === collectionObjectId) {
        dispatch(updateCollectionObjectId(''));
      } else {
        dispatch(updateCollectionObjectId(val));
      }
    } else {
      dispatch(updateFilters(key, val));
    }

    dispatch(searchRegistryCollection);
  };

  const onClear = () => {
    dispatch(clearFilters());
    dispatch(updateCollectionObjectId(''));
    dispatch(searchRegistryCollection);
  };

  const collectionFilter: Filter = {
    key: 'collection_object_id',
    label: 'Categories',
    value: collectionObjectId,
    options: getCollectionOption(collectionList),
  };

  const facetFilters: Filter[] =
    facets?.map((facet) => mapWAwsFacetToFilter(facet, facetValues[facet.key || ''])) || [];

  const filters: Filter[] = [collectionFilter, ...facetFilters].filter(
    (filter) => filter.options.length
  );

  return (
    <Filters
      filters={filters}
      total={registry?.default_gift_card_enabled ? (total || 0) + 1 : total}
      showSort={showSort}
      sortBy={sortBy}
      onChange={onChange}
      onClear={onClear}
    />
  );
};
