import { createSelector } from 'reselect';

import type { RootState } from '@/store';

const getManageRegistry = (state: RootState) => state.manageRegistry;

export const getManageRegistryFilters = createSelector(getManageRegistry, (mr) => mr.filters);
export const getManageRegistryCollectionList = createSelector(
  getManageRegistry,
  (mr) => mr.collection.collection_list || []
);
export const getManageRegistryCollectionObjectId = createSelector(
  getManageRegistry,
  (mr) => mr.collectionObjectId
);
export const getManageRegistrySortBy = createSelector(getManageRegistry, (mr) => mr.sort);
export const getManageRegistryFacets = createSelector(
  getManageRegistry,
  (mr) => mr.collection.facets
);
export const getManageRegistryTotal = createSelector(
  getManageRegistry,
  (mr) => mr.collection.default_collection?.length
);
export const getManageRegistryCollections = createSelector(
  getManageRegistry,
  (mr) => mr.collection.default_collection
);
export const getManageRegistrySelectedItem = createSelector(
  getManageRegistry,
  (mr) => mr.selectedItem
);
export const getManageRegistryEditZolaGiftCard = createSelector(
  getManageRegistry,
  (mr) => mr.editZolaGiftCard
);
export const getManageRegistryCollectionItemsId = createSelector(
  getManageRegistry,
  (mr) => mr.collection.default_collection_object_id
);
