import { SORTS } from './constants';
import { FilterList } from './FilterSideDrawerContent.style';
import { FilterDrawerItem } from './FilterSideDrawerItem';
import { FiltersProps } from './type';

export const FilterSideDrawerContent: React.FC<FiltersProps> = ({
  filters,
  showSort,
  sortBy,
  onChange,
}) => (
  <FilterList>
    {showSort && (
      <FilterDrawerItem
        filter={{
          key: 'sort',
          label: 'Sort',
          isMulti: false,
          options: SORTS,
          value: sortBy,
        }}
        onChange={onChange}
      />
    )}
    {filters.map((filter) => (
      <FilterDrawerItem key={filter.key} filter={filter} onChange={onChange} />
    ))}
  </FilterList>
);
