import { Fragment } from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import {
  DiscountText,
  Price,
  PriceOriginal,
  PriceStrokeThrough,
} from '@/components/common/PricingStrikeThrough/PricingStrikeThrough.styles';
import {
  DISCOUNT_TYPES,
  PRICING_TYPES,
  PromoContentProps,
  PricingStrikeThroughProps,
} from '@/components/common/PricingStrikeThrough/types';
import {
  getPriceFractionDigits,
  getOriginalPriceLabel,
  getPriceLabel,
  formatPromoMessage,
  getRangePrices,
} from '@/components/common/PricingStrikeThrough/utils';
import { convertCurrencyFormat } from '@/util/currencyFormatter';

const PromoContent: React.FC<PromoContentProps> = ({ discountMessaging, discountLink }) => {
  if (!discountMessaging && !discountLink) return null;

  return (
    <DiscountText>
      {discountMessaging}{' '}
      <LinkV2
        variant="secondary"
        className="pdp-promo-detail__link"
        target="_blank"
        rel="noopener noreferrer"
        href={discountLink || ''}
        onClick={(e) => e.stopPropagation()}
      >
        Details
      </LinkV2>
    </DiscountText>
  );
};

export const PricingWithStrikeThrough: React.FC<PricingStrikeThroughProps> = ({
  isPriceHidden,
  referencePrice,
  promoData,
  priceRange,
  price,
}) => {
  if (!price || isPriceHidden) return null;

  let discountLink;
  let discountMessaging;
  let isPostWeddingDiscount;
  const isPromoEnabled = !!promoData;
  const discountType = referencePrice?.type;
  let formattedPrice = convertCurrencyFormat(price, 'USD', getPriceFractionDigits(price), 2);
  let formattedReferencePrice =
    !!discountType && convertCurrencyFormat(referencePrice?.amount, 'USD', 0, 2);

  if (!!priceRange?.min_price && !!priceRange?.max_price) {
    const { lowerBoundPrice: lowerBoundReferencePrice, upperBoundPrice: upperBoundReferencePrice } =
      getRangePrices(referencePrice);
    const { lowerBoundPrice, upperBoundPrice } = getRangePrices(priceRange);
    formattedReferencePrice = upperBoundReferencePrice
      ? `${lowerBoundReferencePrice} - ${upperBoundReferencePrice}`
      : lowerBoundReferencePrice;
    formattedPrice = upperBoundPrice ? `${lowerBoundPrice} - ${upperBoundPrice}` : lowerBoundPrice;
  }

  if (isPromoEnabled) {
    isPostWeddingDiscount = promoData.discount_type === DISCOUNT_TYPES.COMPLETION;
    discountLink = isPostWeddingDiscount
      ? '/wedding-registry/benefits/registry-completion-discount'
      : `/discount-terms/${promoData?.discount_id}`;
    discountMessaging = isPostWeddingDiscount
      ? `${promoData?.discount_amount}% Off With Post-Wedding Discount`
      : formatPromoMessage(promoData);
  }

  const originalPriceLabel = getOriginalPriceLabel(discountType as PRICING_TYPES);
  const priceLabel = getPriceLabel(
    isPromoEnabled && isPostWeddingDiscount
      ? DISCOUNT_TYPES.COMPLETION
      : (discountType as PRICING_TYPES)
  );

  return (
    <Fragment>
      <PromoContent discountMessaging={discountMessaging} discountLink={discountLink} />
      {!!discountType && (
        <PriceOriginal>
          {originalPriceLabel} <PriceStrokeThrough>{formattedReferencePrice}</PriceStrokeThrough>
        </PriceOriginal>
      )}
      <Price isDiscounted={!!discountType}>
        {priceLabel}
        {formattedPrice}
      </Price>
    </Fragment>
  );
};
