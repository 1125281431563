import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

import styled from '@emotion/styled';

export const FiltersContainer = styled.div`
  padding: 16px 0;
  ${MEDIA_QUERY.DESKTOP} {
    padding: 24px 0;
  }
`;

export const FilterPillsContainer = styled.div`
  margin-top: 16px;
`;
