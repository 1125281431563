import CloseIcon from '@zola/zola-ui/src/components/SvgIcons/Close';

import { Header } from './FilterSideDrawerHeader.style';

interface FilterSideDrawerHeaderProps {
  handleClose?: () => void;
}
export const FilterSideDrawerHeader: React.FC<FilterSideDrawerHeaderProps> = ({ handleClose }) => (
  <Header>
    <h3>Filters</h3>
    <CloseIcon onClick={handleClose} />
  </Header>
);
