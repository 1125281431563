import { DesktopFiltersContainer, FiltersContainer, SortContainer } from './DesktopFilters.styles';
import { FilterDropdown } from './FilterDropdown';
import { SortDropdown } from './SortDropdown';
import { FiltersProps } from './type';

export const DesktopFilters: React.FC<FiltersProps> = ({
  filters,
  total,
  showSort,
  sortBy = '',
  onChange,
}) => (
  <DesktopFiltersContainer>
    <FiltersContainer>
      {filters.map((filter) => (
        <FilterDropdown key={filter.key} filter={filter} onChange={onChange} />
      ))}
      {showSort && (
        <SortContainer>
          <div>{total} gifts</div>
          <SortDropdown value={sortBy} onChange={(val: string) => onChange('sort', val)} />
        </SortContainer>
      )}
    </FiltersContainer>
  </DesktopFiltersContainer>
);
